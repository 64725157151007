import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.SEARCH.TEXT',
        icon: 'bx-search',
        link: '/',
    },
    {
        id: 2,
        label: 'MENUITEMS.TICKETSBOARD.TEXT',
        icon: 'bx bxs-dashboard',
        link: '/tickets/ticketsboard',
    },
    {
        id: 3,
        label: 'MENUITEMS.REGISTERS.TEXT',
        icon: 'bx-user',
        subItems: [
            {
                id: 4,
                label: 'MENUITEMS.REGISTERS.USERS',
                link: '/registers/users',
            },
        ]
    },
    // {
    //     id: 5,
    //     label: 'MENUITEMS.NEWTICKET.TEXT',
    //     icon: 'bx-purchase-tag',
    //     link: '/ticket/new-ticket',
    // },
];

