import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { LayoutComponent } from './layouts/layout.component';
import { Page404Component } from './extrapages/page404/page404.component';

const routes: Routes = [
  { path: "", redirectTo: "account/login", pathMatch: "full" },
  { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
  { path: 'ticket', loadChildren: () => import('./ticket/ticket.module').then(m => m.TicketModule) },
  { path: 'satisfaction-survey', loadChildren: () => import('./satisfaction-survey/satisfaction-survey.module').then(m => m.SatisfactionSurveyModule) },
  // tslint:disable-next-line: max-line-length
  { path: '' , component: LayoutComponent, loadChildren: () => import('./tickets/tickets.module').then(m => m.TicketsModule), canActivate: [AuthGuard]},
  { path: 'registers' , component: LayoutComponent, loadChildren: () => import('./registers/registers.module').then(m => m.RegistersModule), canActivate: [AuthGuard]},
  { path: 'auth', loadChildren: () => import('./account/auth/auth.module').then(m => m.AuthModule) },
  { path: 'pages',  loadChildren: () => import('./extrapages/extrapages.module').then(m => m.ExtrapagesModule), canActivate: [AuthGuard] },
  { path: '**', component: Page404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
